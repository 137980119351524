import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <section className='section container ' id='Contact'>
      <h2 className='section__title'>Contact</h2>
      <span className='section__subtitle'>Let's talk, Don't be shy! Hit me up! 👇</span>
      <div className="contact_icon">
        <div className="contact_icon-box">
        <i class="uil uil-location-pin-alt"></i>
          <div className="contact_info">
            <h3>Location</h3>
            <p>Mekelle, Tigray, Ethiopia</p>
          </div>
        </div>
        <div className="contact_icon-box">
          <i class="uil uil-envelope-minus"></i>
          <div className="contact_info">
            <h3>Mail</h3>
            <a href = "mailto: Sineworknegasi38@gmail.com">Sineworknegasi38@gmail.com</a>
          </div>
        </div>
        <div className="contact_icon-box">
        <i class="uil uil-phone-alt"></i>
          <div className="contact_info">
            <h3>Contact </h3>
            <a>+251966855900</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact