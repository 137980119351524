import React from "react";

const SoftSkill = [
  {
    name:"commincation",
    level:"Clear and effective"
  },
  {
    name:"Teamwork",
    level:"collaboration"
  },
  {
    name:"Problem-solving",
    level:"solving problems"
  },
  {
    name:"Time management",
    level:"Effective"
  },
  {
    name:"Critical thinking",
    level:"critical thinking"
  },
  {
    name:"Continuous learning",
    level:"staying up-to-date"
  },
]



const Softskills = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Soft Skills</h3>

      <div className="skills__box">
        {SoftSkill.map((skill) => {
          return (
            <div className="skills__group">
            <div className="skills__data">
              <i className="bx bx-badge-check"></i>
              <div>
                <h3 className="skills__name">{skill.name}</h3>
                <span className="skills__level">{skill.level}</span>
              </div>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  );
};

export default Softskills;
