import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
      <a className="home__social-icon" target='_black' href='https://www.tiktok.com/@sinework_negasi'>
      <i class='bx bxl-tiktok'></i>
        </a>
        <a className="home__social-icon" target='_black' href='https://www.instagram.com/sinework_negasi/'>
            <i className="uil uil-instagram"></i>
        </a>
        <a className="home__social-icon" target='_black' href='https://www.facebook.com/sineworknegasi'>
        <i class="uil uil-facebook"></i>
        </a>
        <a className="home__social-icon" target='_black' href='https://github.com/Sineworknegasi'>
        <i class="uil uil-github-alt"></i>
        </a>
    </div>
  )
}

export default Social
