import React from "react";
import "./portfolio.css";
import { FaGithub } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import "react-slideshow-image/dist/styles.css";

const projectsData = [
  {
    name: "Burger House",
    Description:
      "This is a burger house website. It contains the menu of the restaurant and the contact information of the restaurant.",
    Github: "https://github.com/Sineworknegasi/Burger_House.git",
    image:
      "https://1.bp.blogspot.com/-lswTS41qAa0/TeodV4P9rWI/AAAAAAAAGQ4/MZjFSzdEdGQ/s1600/DSC00710.JPG",
    veiw: "https://burgerhouse2.pages.dev/",
  },
  {
    name: "Sami Gym",
    Description:
      "Welcome to SamiGym, an immersive fitness experience brought to life through the power of React JS.",
    Github: "https://github.com/Sineworknegasi/sami_gym.git",
    image:
      "https://colorlib.com/wp/wp-content/uploads/sites/2/gym2-free-template-1024x807.jpg",
    veiw: "https://sami-gym.pages.dev/",
  },
  {
    name: "Yegna Cafe website",
    Description:
    "This is a Yegna Cafe website. It contains the menu of the coffee and the contact information of the restaurant.using React and Bootstrap",
    Github: "https://github.com/Sineworknegasi/yegna_cafe/tree/main",
    image:"https://th.bing.com/th/id/OIG3.8S52C8AUglrLjpOEI3Qu?w=1024&h=1024&rs=1&pid=ImgDetMain",
    veiw: "https://yegnacafe.pages.dev/",
  },
  {
    name: "Gpt3 Landing Page",
    Description:
      "Welcome to GPT-3 Land—a cutting-edge platform that harnesses the power of OpenAI's GPT-3 technology to revolutionize the way we interact with language, all brought to life through the versatility of React JS.",
    Github: "https://github.com/Sineworknegasi/google_keep_clone.git",
    image:
      "https://blog.bitsacard.com/wp-content/uploads/2020/11/GPT3-L-nueva-inteligencia-artificial_1-1-1536x1152.jpg",
    veiw: "https://5be8adb1.gpt3jsm.pages.dev/",
  },
  {
    name: "Care-hub Charity website",
    Description:"Welcome to Care Hub, where compassion meets action! Our website, built with React and Bootstrap, is your gateway to making a difference in the world ",
    Github: "https://github.com/Sineworknegasi/carehub",
    image: "https://th.bing.com/th/id/OIG1.i3DnYJgKuDnwwp6etaUg?w=1024&h=1024&rs=1&pid=ImgDetMain",
    veiw: "https://carehub.pages.dev/"
  },
  {
    name: "Sinecutz Barber website",
    Description:"Welcome to Sine Cutz, where style meets precision! Our barber website is meticulously crafted with React and Bootstrap to offer you a seamless experience as you explore our services and book your next appointment.",
    Github: "https://github.com/Sineworknegasi/Sinecutz",
    image: "https://th.bing.com/th/id/OIG2.yzqENKpVuAakkiqbFkN_?w=1024&h=1024&rs=1&pid=ImgDetMain",
    veiw: "https://sinecutz.pages.dev/"
  }
];

const projectsCard = projectsData.map((project) => {
  return (
    <div className="Project_card">
      <div className="Project_card_img">
        <img src={project.image} alt="" />
      </div>
      <div className="Project_card_content">
        <h3 className="Project_card_title">{project.name}</h3>
        <p className="Project_card_description">{project.Description}</p>
        <div className="projects_link">
          <a href={project.Github} target="_blank" className="project_btn">
            <FaGithub className="button__ic" /> Github
          </a>
          <a href={project.veiw} target="_blank" className="project_btn" >
            <FaEye className="button__ic" />
            Veiw Demo
          </a>
        </div>
      </div>
    </div>
  );
});

const Portofolio = () => {
  return (
    <section className="portfolio section" id="Portfolio">
      <h2 className="section__title">Portfolio</h2>
      <span className="section__subtitle">My Projects</span>
      <div className="section_projects">
        {projectsCard}
      </div>
    </section>
  );
};

export default Portofolio;
