import React from "react";
import Social from "../home/Social";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footerrc">
          <h3>
            Copyright &copy; {new Date().getFullYear()}. All rights are reserved
          </h3>
          <div className="social">
            <a
              className="home__social-icon"
              target="_black"
              href="https://www.instagram.com/sinework_negasi/"
            >
              <i className="uil uil-instagram"></i>
            </a>
            <a
              className="home__social-icon"
              target="_black"
              href="https://www.facebook.com/sineworknegasi"
            >
              <i class="uil uil-facebook"></i>
            </a>
            <a
              className="home__social-icon"
              target="_black"
              href="https://github.com/Sineworknegasi"
            >
              <i class="uil uil-github-alt"></i>
            </a>
            <a className="home__social-icon" target='_black' href='https://www.tiktok.com/@sinework_negasi'>
      <i class='bx bxl-tiktok'></i>
        </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
